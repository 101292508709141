import React from "react"

export default function GalleryAside({ posts }) {
  return (
    <aside className={`col-3md bg-dark sidebar txt-white sidebar_noheader`}>
      <nav id="sidebar-menu">
        <div className="mdplus-only">
          {["Twarz", "Tułów"].map((title, key) => (
            <div key={key}>
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
              <ul>
                {posts
                  .filter((element) => element.category === title)
                  .map((element, subkey) => (
                    <li>
                      <a href={`/galeria/?q=${element.slug.replace('/zabiegi/', '')}`}
                         dangerouslySetInnerHTML={{ __html: element.title }} />
                    </li>
                  ))
                }
              </ul>
            </div>
          ))}
        </div>
      </nav>
    </aside>
  )
}
