import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { useEffect, useState } from "react"
import { graphql } from "gatsby"
import GalleryAside from "../components/galleryAside"

export default function Galeria({ data }) {
  const seo = {
    title: "Galeria operacji plastycznych | Chirurgia Plastyczna w Szczecinie",
    description: "Plastyka powiek / Korekta nosa / Korekcja uszu / Powiększanie piersi / Podniesienie opadniętych piersi / Ginekomastia / Liposukcja...",
    url: "https://mpastucha.pl/galeria"
  }

  const [active, setActive] = useState(false);

  const gallery = data?.gallery?.edges?.map(({ node }) => node)
  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.ProceduresPageInit && window.OpinionPageInit && window.$) {
        clearInterval(interval)
        window.ProceduresPageInit()
        window.OpinionPageInit()
      }
    }, 50)

    if (typeof window !== "undefined" && window.location.href.includes('?q')) {
      setActive(window.location.href.split('?q=')[1]);
    } else {
      setActive('plastyka-powiek-gornych-i-dolnych');
    }
  }, [active])

  return (
    <Layout headerClasses={"mainheader-subpage mainheader-white"} classes={"bg-creme"}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img
        src="/images/phone.svg" alt="Ikona telefon" /></a>
      <div className="container-fluid sct">
        <div className="row pad-l pad-r">
          <GalleryAside posts={gallery} />
          <section className="col-9md sct-content">
            <div className="row">
              {gallery.filter((item) => item.slug.includes(active)).map((item, key) => (
                  <div className="col-12" key={key}>
                    <h3 className="special_header">{item.title}</h3>
                    <a href={item.slug} className="btn-link-arrow"><span className="ico-arrow" /> przejdź do zabiegu</a>
                    <div className="row">
                      <div className={`thumbnails ${item.slug.includes(active) ? 'imageGallery' : ''}`}>
                        {item.images.map((image, subkey) => (
                          <div className="col-4xs" key={subkey}>
                            <div className="thumbnails-photo-gallery">
                              <a href={image.fullsize} data-size="1731x578">
                                <img src={image.thumbnail} alt="" />
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
      </div>

    </Layout>
  )
}


export const pageQuery = graphql`
    query Gallery {
        gallery: allGalleryYaml {
            edges {
                node {
                    slug
                    title
                    category
                    images {
                        thumbnail
                        fullsize
                    }
                }
            }
        }
    }`
